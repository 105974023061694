import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Apis from '../../apis';
import TreeForm from './TreeForm';
import { getUser } from '../../services/auth';

const TreeAddDialog = ({ lat, lng, onClose }) => {
  const open = !!lat && !!lng;
  const [firstName, lastName] =
    getUser().username?.split(' ') ?? [];

  const handleSubmit = async (formData) => {
    const data = await Apis.createTree(formData);
    onClose(data);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>ข้อมูลการปลูกต้นไม้</DialogTitle>
      <DialogContent>
        {open && (
          <TreeForm
            defaultValues={{
              lat,
              lng,
              firstName,
              lastName,
            }}
            onSubmit={handleSubmit}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TreeAddDialog;

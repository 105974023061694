import React, { useState, useRef, useEffect } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import TreeAddDialog from '../components/Tree/TreeAddDialog';
import useRequest from '../hooks/useRequest';
import Apis from '../apis';

const render = (status) => {
  if (status === 'LOADING') return <h3>... {status} ...</h3>;
  if (status === 'FAILURE') return <h3>### {status} ###</h3>;
  return null;
};

let map;
let infoWindow;

const showInfoWindow = ({ position, customData }) => {
  let content = `ชื่อ - สกุล : ${customData.attributes.firstName ?? '-'} xxxxxxx`;
  customData.attributes.images.data?.forEach((image) => {
    content += `<br /><br /><img src="${process.env.GATSBY_BACKEND_URL}${image.attributes.url}" style="max-height: 150px;" />`;
  });

  infoWindow?.close();
  infoWindow = new window.google.maps.InfoWindow({
    position,
    content,
    pixelOffset: new window.google.maps.Size(0, -25),
  });
  infoWindow.open(map);
};

const createTreeMarker = ({ position, map, customData }) => {
  const image = {
    url: '/garden.png',
    size: new window.google.maps.Size(24, 24),
    origin: new window.google.maps.Point(0, 0),
  };

  const marker = new window.google.maps.Marker({
    position,
    map,
    icon: image,
    title: 'A Tree',
    customData,
  });

  marker.addListener('click', (mapsMouseEvent) => {
    showInfoWindow({ position: mapsMouseEvent.latLng, customData });
  });

  return marker;
};

function MyMapComponent({ center, zoom }) {
  const [latLng, setLatLng] = useState(null);
  const [treeCount, setTreeCount] = useState(0);

  const handleClose = (data) => {
    setLatLng(null);
    const tree = data?.data;
    if (tree) {
      createTreeMarker({
        map,
        position: { lat: tree.attributes.lat, lng: tree.attributes.lng },
        customData: tree,
      });

      showInfoWindow({
        position: { lat: tree.attributes.lat, lng: tree.attributes.lng },
        customData: tree,
      });

      setTreeCount((oldValue) => oldValue + 1);
    }
  };

  const ref = useRef();

  const [{ data }] = useRequest(Apis.getTrees);
  const trees = data?.data;
  const meta = data?.meta;

  useEffect(() => {
    map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    const myloc = new window.google.maps.Marker({
      clickable: false,
      icon: new window.google.maps.MarkerImage(
        '//maps.gstatic.com/mapfiles/mobile/mobileimgs2.png',
        new window.google.maps.Size(22, 22),
        new window.google.maps.Point(0, 18),
        new window.google.maps.Point(11, 11)
      ),
      shadow: null,
      zIndex: 999,
      map,
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          const me = new window.google.maps.LatLng(
            pos.coords.latitude,
            pos.coords.longitude
          );
          myloc.setPosition(me);
          map.setCenter(me);
          map.setZoom(20);
        },
        function (error) {
          // ...
        }
      );
    }

    map.addListener('click', (mapsMouseEvent) => {
      // const marker = createTreeMarker({ map, position: mapsMouseEvent.latLng });
      setLatLng(mapsMouseEvent.latLng);
      // marker.setMap(null)
    });
  }, [center, zoom]);

  useEffect(() => {
    if (trees) {
      setTreeCount(meta.pagination.total);
      trees.forEach((tree) => {
        createTreeMarker({
          map,
          position: { lat: tree.attributes.lat, lng: tree.attributes.lng },
          customData: tree,
        });
      });
    }
  }, [trees, meta]);

  return (
    <>
      <div
        ref={ref}
        id="map"
        style={{ width: '100%', height: 'calc(100vh - 68.49px)' }}
      />
      {meta && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            top: '140px',
            left: '20px',
            zIndex: 99,
            fontSize: '20px',
          }}
        >
          ต้นไม้ทั้งหมด {treeCount} ต้น
        </div>
      )}
      <TreeAddDialog
        lat={latLng ? latLng.lat() : null}
        lng={latLng ? latLng.lng() : null}
        onClose={handleClose}
      />
    </>
  );
}

const IndexPage = () => {
  const center = { lat: 13.7323215, lng: 100.570237 };
  const zoom = 12;

  return (
    <Wrapper apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY} render={render}>
      <MyMapComponent center={center} zoom={zoom} />
    </Wrapper>
  );
};

export default IndexPage;

const axios = require('axios');
const { getUser, logout } = require('../services/auth');

const instance = axios.create({
  baseURL: process.env.GATSBY_BACKEND_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if ((error.status = 401)) {
      logout()
      window.location.reload()
    }
    return Promise.reject(error);
  }
);

const getTrees = async () => {
  const { jwt } = getUser();

  if (!jwt) return null;

  try {
    const { data } = await instance.get(
      '/api/trees?pagination[pageSize]=10000&populate=*',
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    return data;
  } catch (error) {
    return null;
  }
};

const createTree = async (payload) => {
  const { jwt } = getUser();

  if (!jwt) return null;

  try {
    const { data } = await instance({
      method: 'post',
      url: '/api/trees?populate=*',
      data: payload,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwt}`,
      },
    });

    return data;
  } catch (error) {
    return null;
  }
};

module.exports = {
  getTrees,
  createTree,
};

import axios from 'axios';

const lookupAddress = async ({ lat, lng }) => {
  const { data } = await axios.get(
    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
  );
  return data;
};

const exported = {
  lookupAddress,
};

export default exported;

import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import OpenStreetMap from '../../utils/OpenStreetMap';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { getUser } from '../../services/auth';

const schema = Joi.object({
  placeName: Joi.string().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  note: Joi.string().allow(''),
  lat: Joi.number().min(-90).max(90).required(),
  lng: Joi.number().min(-180).max(180).required(),
  images: Joi.object().required(),
});

const TreeForm = ({ defaultValues, onSubmit }) => {
  const [placeName, setPlaceName] = useState('');
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      placeName: '',
      firstName: '',
      lastName: '',
      note: '',
      lat: null,
      lng: null,
      images: null,
      ...defaultValues,
    },
  });

  useEffect(() => {
    if (!defaultValues.placeName && defaultValues.lat && defaultValues.lng) {
      OpenStreetMap.lookupAddress({
        lat: defaultValues.lat,
        lng: defaultValues.lng,
      }).then((data) => {
        setPlaceName(data?.display_name ?? 'Unknown Place');
        setValue('placeName', data?.display_name ?? 'Unknown Place');
      });
    }
  }, [defaultValues, setValue]);

  const onValid = (data) => {
    const formData = new FormData();
    const { images, ...others } = data;
    // Workaround dangerously add creator & updater
    const userId = getUser().id;
    others.creator = userId;
    others.updater = userId;

    formData.append('data', JSON.stringify(others));
    if (images) {
      [...images].forEach((image) => {
        formData.append(`files.images`, image, image.name);
      });
    }
    onSubmit(formData);
  };

  const onInvalid = (...args) => {
    console.log(args);
  };

  return (
    <form onSubmit={handleSubmit(onValid, onInvalid)}>
      <Controller
        name="placeName"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            multiline
            disabled={!placeName}
            rows={2}
            required
            variant="standard"
            label="สถานที่"
            margin="dense"
            error={!!errors.placeName}
            helperText={errors.placeName?.message}
            {...field}
          />
        )}
      />
      <div>
        <small>
          Coordinates: {defaultValues.lat}, {defaultValues.lng}
        </small>
      </div>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            variant="standard"
            label="ชื่อ"
            margin="dense"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            variant="standard"
            label="สกุล"
            margin="dense"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="note"
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="standard"
            label="ชื่อต้นไม้"
            margin="dense"
            error={!!errors.note}
            helperText={errors.note?.message}
            {...field}
          />
        )}
      />
      <input
        style={{ marginTop: 16 }}
        accept="image/*"
        type="file"
        multiple
        {...register('images')}
      />
      {errors.images?.message && (
        <div>
          <span style={{ color: 'red' }}>ต้องเลือกรูปภาพ</span>
        </div>
      )}
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button type="submit">บันทึก</Button>
      </Box>
    </form>
  );
};

export default TreeForm;

import { useReducer, useMemo, useEffect } from 'react';

export const ACTION_RESET = 'reset';
export const ACTION_REQUEST = 'request';
export const ACTION_SUCCESS = 'success';
export const ACTION_FAILED = 'failed';

const INITIAL_STATE = {
  loading: false,
  data: null,
  hasError: false,
  error: null,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTION_RESET:
      return {
        ...INITIAL_STATE,
      };
    case ACTION_REQUEST:
      return {
        loading: true,
        data: null,
        hasError: false,
        error: null,
      };
    case ACTION_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        hasError: false,
        error: null,
      };
    case ACTION_FAILED:
      return {
        loading: false,
        data: null,
        hasError: true,
        error: action.payload.error,
      };
    default:
      throw new Error();
  }
}

export const useRequestReducer = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const { dispatchActionRequest, dispatchActionSuccess, dispatchActionFailed } =
    useMemo(
      () => ({
        dispatchActionRequest: () => dispatch({ type: ACTION_REQUEST }),
        dispatchActionSuccess: (data) =>
          dispatch({
            type: ACTION_SUCCESS,
            payload: {
              data,
            },
          }),

        dispatchActionFailed: (error) =>
          dispatch({
            type: ACTION_FAILED,
            payload: {
              error,
            },
          }),
      }),
      []
    );

  return [
    state,
    { dispatchActionRequest, dispatchActionSuccess, dispatchActionFailed },
  ];
};

const useRequest = (requestFn, ...requestFnParams) => {
  const [
    state,
    { dispatchActionRequest, dispatchActionSuccess, dispatchActionFailed },
  ] = useRequestReducer();

  const stringRequestFnParams = JSON.stringify(requestFnParams);

  const memoizedRequestFn = useMemo(
    () => () => requestFn(...JSON.parse(stringRequestFnParams)),
    [requestFn, stringRequestFnParams]
  );

  useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      dispatchActionRequest();

      try {
        const data = await memoizedRequestFn();
        if (isMounted) {
          dispatchActionSuccess(data);
        }
      } catch (error) {
        if (isMounted) {
          dispatchActionFailed();
        }
      }
    };

    getData();

    return () => {
      isMounted = false;
    };
  }, [
    memoizedRequestFn,
    dispatchActionRequest,
    dispatchActionSuccess,
    dispatchActionFailed,
  ]);

  const makeRequest = async () => {
    dispatchActionRequest();

    try {
      const data = await memoizedRequestFn();
      dispatchActionSuccess(data);
    } catch (error) {
      dispatchActionFailed();
    }
  };

  return [state, { makeRequest }];
};

export default useRequest;
